<template>
  <div class="table-list" style="padding-top: 15px;height: 800px">
    <div v-html="handbook"></div>
  </div>
</template>
<script>
// import VueMarkdown from 'vue-markdown'
import _aSRabilitys from "@/api/open/ASRabilitys";
export default {
  data() {
    return {
      handbook: '<p>111</p>'
    };
  },
  methods: {
    async getPdf () {
      const res = await _aSRabilitys._statisticsService.getPdf()
      console.log(this.$refs.editor)
      this.handbook = res
    }
  },
  mounted () {
        window.open( 'https://duofangai.com/')
console.log('999999999999999999999')
    this.getPdf()
  }
};
</script>
import { Request } from '@/http/request'
const obj = {
    "useStatusText": "开启",
    "updateTime": "2022-07-13 16:37:09",
    "asrPrice": 1.0000,      
    "serviceText": "阿里引擎",
    "usePrice": 1.0000,    
    "useStatus": 0,
    "uid": "100008",
    "createTime": "2021-09-25 11:25:38",
    "service": 1,
    "apiPrice": 0.0040,    
    "id": "9",
    "asrStatus": 0,
    "apiStatusText": "开启",
    "apiStatus": 0
}

class StatisticsService {
    // 用户统计展示
    async getTotal () {
        const res = await Request.axiosInstance.post('/open/voice-count/total-api-count')
        return res.data
    }
    // 用户页面存在用户价格查询
    async getPrice (params) {
        const res = await Request.axiosInstance.post('/open/voice-user-price/get-by-uid', params)
        return res
    }
    // 列表查询
    async getList (params) {
        const res = await Request.axiosInstance.post('/open/voice-count/check-api-count', params)
        return res.data
    }
    async getPdf() {
        const res = await Request.axiosInstance.get('/md/测试.md');
        return res
    }
}

export default {
    _statisticsService: new StatisticsService()
}